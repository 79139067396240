<template>
  <div class="mobile-detail">
    <div class="content_container full-line" style="margin-bottom: 0">
      <cube-scroll v-if="detail">
        <inputItem title="任务名称" :initialContent="detail.name" :disabled="true"></inputItem>
        <inputItem title="负责人员" :initialContent="detail.user_name" :disabled="true"></inputItem>
        <inputItem title="协同人员" :initialContent="detail.users" :disabled="true"></inputItem>
        <inputItem title="任务类型" :initialContent="detail.type" :disabled="true"></inputItem>
        <inputItem title="任务等级" :initialContent="detail.priority" :disabled="true"></inputItem>
        <inputItem title="完成标志" :initialContent="detail.finished_sign" :disabled="true"></inputItem>
        <inputItem title="完成时间" :initialContent="formatDate(detail.finished_time)" :disabled="true"></inputItem>
        <inputItem title="协同资源" :initialContent="detail.resources" :disabled="true"></inputItem>
        <inputItem title="任务备注" :initialContent="detail.remark" :disabled="true"></inputItem>
        <div v-html="detail.memo" style="margin-top: 10px;line-height: 20px;font-size: 14px;"></div>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
import { GetTask } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
    };
  },
  methods: {
    formatDate: formatDate,
    dataRequest: function () {
      loadDetailAction(this, GetTask, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>